body {
    margin: 0;
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: flex;
    flex-direction: column;
    height: 100vh;
    background: url("../assets/nick-hillier-aQcE3g.jpg") no-repeat center;
    background-size: cover;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 16px;
    width: 100%;
}

.container {
    padding-left: 32px;
    padding-right: 32px;
    margin-left: auto;
    margin-right: auto;
}

.btn {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    transition: transform .2s ease-in-out;
}

.btn:active {
    transform: scale(.98);
}

.btn__nav {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: .8px;
    text-align: right;
}

.wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: 100%;*/
}

.block {
    margin: 0 260px;
    width: 100%;
}

.text {
    font-size: 16px;
    color: #4A4A4A;
    letter-spacing: 0;
    line-height: 22px;
}

.btn__primary {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    border-radius: 5px;
    background-color: #6515DD;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.input {
    width: 100%;
    margin-bottom: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    border: 1px solid #C9CACC;
    outline: none;
    transition: all .2s ease-in-out;
}

.title {
    font-weight: 600;
    font-size: 46px;
    line-height: 54px;
    color: #FFFFFF;
    margin: 0;
}

.text {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
}

.text-position {
    margin: 8px 0 16px;
}

.input:focus {
    border-color: #6515DD;
}

.or {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #C9CACC;
    letter-spacing: .8px;
    text-align: center;
}

.or__text {
    display: block;
    margin: 0 32px;
}

.or__line {
    display: block;
    height: 1px;
    background-color: #C9CACC;
    width: 100%;
    max-width: 144px;
}

.footer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
}

.footer-content {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 6px 16px;
    margin: 0 32px;
    border: 1px solid #F5F5F5;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.footer-content svg {
    display: block;
}

.footer-content p {
    margin: 0 0 0 32px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
}

a {
    text-decoration: none;
    color: #6E37D8;
}

@media (max-width: 1090px) {
    .block {
        margin-left: 32px;
        margin-right: 32px;
    }

    .footer-content {
        margin: 0;
        align-items: center;
    }

    .footer-content p {
        margin-top: 0;
        margin-left: 16px;
    }

    .title {
        font-size: 32px;
        line-height: 44px;
    }

    body {
        background-position-x: left;
    }

    .footer {
        margin-bottom: 0;
    }
}

* {
    box-sizing: border-box;
}
